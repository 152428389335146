import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import { IconButton, TextField } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import './PdfViewer.css'; // Import the CSS file

// Initialize Fluent UI icons
initializeIcons();

// Set the worker src to a CDN for PDF.js
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;

const PdfViewer = ({ pdfDetails, initialPdfIndex = 0, setShowPdfViewer }) => {

  const canvasRef = useRef(null);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(initialPdfIndex);
  const renderingRef = useRef(false);

  useEffect(() => {
    if (pdfDetails && pdfDetails.length > 0) {
      const selectedPdf = pdfDetails[selectedPdfIndex];
      const loadingTask = getDocument(selectedPdf.pdfUrl);

      loadingTask.promise.then(
        (pdf) => {
          setPdfDoc(pdf);
          setTotalPages(pdf.numPages);
          setPageNum(selectedPdf.page); // Set the initial page from pdfDetails
          renderPage(selectedPdf.page, pdf);
        },
        (reason) => {
          console.error('Error loading PDF: ', reason);
        }
      );
    }
  }, [selectedPdfIndex, pdfDetails]);

  const renderPage = (num, pdf) => {
    if (!pdf || renderingRef.current || !canvasRef.current) return;
    renderingRef.current = true;

    pdf.getPage(num).then((page) => {
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Set canvas dimensions
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      context.clearRect(0, 0, canvas.width, canvas.height);

      page.render(renderContext).promise.then(() => {
        renderingRef.current = false;
      });
    });
  };

  const handleNextPage = () => {
    if (pdfDoc && pageNum < totalPages) {
      setPageNum(pageNum + 1);
      renderPage(pageNum + 1, pdfDoc);
    }
  };

  const handlePrevPage = () => {
    if (pdfDoc && pageNum > 1) {
      setPageNum(pageNum - 1);
      renderPage(pageNum - 1, pdfDoc);
    }
  };

  const handlePageInputChange = (event) => {
    const newPageNum = Number(event.target.value);
    if (newPageNum >= 1 && newPageNum <= totalPages) {
      setPageNum(newPageNum);
      renderPage(newPageNum, pdfDoc);
    }
  };

  const handleCloseViewer = () => {
    setShowPdfViewer(false);
  };

  return (
    <div className="pdf-viewer-container">
      <IconButton
        iconProps={{ iconName: 'Cancel' }}
        title="Close PDF Viewer"
        ariaLabel="Close PDF Viewer"
        onClick={handleCloseViewer}
        className="pdf-viewer-close-btn"
      />

      <div className="pdf-selection-buttons">
        {pdfDetails.map((pdf, index) =>
          pdf.filename ? (
            <button
              key={index}
              onClick={() => setSelectedPdfIndex(index)}
              className={`pdf-selection-btn ${selectedPdfIndex === index ? 'selected' : ''}`}
            >
              {pdf.filename}
            </button>
          ) : null
        )}
      </div>

      <div className="pdf-canvas-container">
        <div className="pdf-canvas-wrapper">
          <canvas
            ref={canvasRef}
            className="pdf-canvas"
          />
        </div>
      </div>

      <div className="pdf-pagination-controls">
        <IconButton
          iconProps={{ iconName: 'ChevronLeft' }}
          title="Previous Page"
          ariaLabel="Previous Page"
          onClick={handlePrevPage}
          disabled={pageNum <= 1}
        />

        <span className="pdf-pagination-text">Page</span>

        <TextField
          type="number"
          value={pageNum}
          onChange={handlePageInputChange}
          className="pdf-page-input"
        />

        <span className="pdf-pagination-text">of {totalPages}</span>

        <IconButton
          iconProps={{ iconName: 'ChevronRight' }}
          title="Next Page"
          ariaLabel="Next Page"
          onClick={handleNextPage}
          disabled={pageNum >= totalPages}
        />
      </div>
    </div>
  );
};

export default PdfViewer;
