import React, { useState, useRef } from 'react';
import { TextField } from "@fluentui/react";
import { Mic28Filled, Send28Filled } from "@fluentui/react-icons";
import { getTokenOrRefresh } from "../Speech2Text/token_util";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import styles from "./QuestionInput.module.css";

const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, onClearChat }) => {
    const [question, setQuestion] = useState("");
    const [isMicActive, setIsMicActive] = useState(false); // Track microphone status
    const textFieldRef = useRef(null); // Ref to focus the TextField on tap/click

    // --- SPEECH TO TEXT
    async function sttFromMic() {
        setIsMicActive(true); // Set microphone as active
        const tokenObj = await getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = "en-US";

        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

        setQuestion("Speak into your microphone...");

        recognizer.recognizeOnceAsync(result => {
            setIsMicActive(false); // Set microphone as inactive after speech is recognized
            if (result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                setQuestion(result.text || "");
            } else {
                setQuestion("ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.");
            }
        });
    }

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    // Focus the input on click or tap
    const handleTextFieldClick = () => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    };

    return (
        <div className={styles.inputWrapper} onClick={handleTextFieldClick}>
            <TextField
                componentRef={textFieldRef} // Set the ref to TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={(_ev, newValue) => setQuestion(newValue || "")}
                onKeyDown={onEnterPress}
            />
            <div className={styles.buttonBox}>
                <div
                    className={`${styles.primaryButton} ${styles.microphoneButton} ${isMicActive ? styles.activeMic : ""}`}
                    aria-label="Microphone button"
                    onClick={sttFromMic}
                >
                    <Mic28Filled primaryFill={isMicActive ? "white" : "black"} style={{ width: "24px", height: "24px" }} />
                </div>
                <div
                    className={`${styles.primaryButton} ${styles.sendButton} ${disabled || !question.trim() ? styles.primaryButtonDisabled : ""}`}
                    aria-label="Ask question button"
                    onClick={sendQuestion}
                >
                    <Send28Filled primaryFill="white" style={{ width: "24px", height: "24px" }} />
                </div>
            </div>
        </div>
    );
};

export default QuestionInput;
