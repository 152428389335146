import React, { useState } from 'react';
import LoadingCircle from './LoadingCircle'; // Import the loading circle component
import { Pause24Regular } from "@fluentui/react-icons"; // Import a pause icon from Fluent UI or an appropriate library

export default function AvatarPlayer({ loading, showPdfViewer, anamClient, disconnected, crop }) {
  // Function to handle the pause button click
  const handlePauseClick = () => {
    if (anamClient && typeof anamClient.talk === 'function') {
      console.log("stop anam speaking");
      anamClient.talk("\n"); // Invoke the talk function on talkStream
    }
  };

  // Function to reload the page
  const handleReloadPage = (e) => {
    if (disconnected) {
      window.location.reload(); // Reloads the current page only if disconnected
    }
    e.stopPropagation(); // Prevent event bubbling if not disconnected
  };

  return (
    <div
      onClick={handleReloadPage} // Attach the reload handler
      style={{
        width: showPdfViewer ? '200px' : '100%', // Ensure AvatarPlayer fits within the container width
        height: showPdfViewer ? '150px' : '100%', // Ensure AvatarPlayer fills the parent height
        display: 'flex',
        flexDirection: 'column',
        alignItems: showPdfViewer ? 'flex-start' : 'center',
        margin: showPdfViewer ? '10px' : '0 auto',
        position: showPdfViewer ? 'fixed' : 'relative', // Positioned relative to the container
        zIndex: 1, // Keep it above other elements if needed
        boxSizing: 'border-box', // Make sure padding doesn't cause overflow
        top: showPdfViewer ? '0' : 'auto', // Align to the top of the viewport if showPdfViewer is true
        left: showPdfViewer ? '0' : 'auto', // Align to the left of the viewport if showPdfViewer is true
        zIndex: showPdfViewer ? '1000' : 'auto', // Ensures video appears on top when in top-left corner
        cursor: disconnected ? 'pointer' : 'default', // Only clickable if disconnected
      }}
    >
      <div
        id="video-container"
        className="justify-center flex bg-grey-400 border-none w-full rounded-xl overflow-hidden"
        style={{
          width: '100%', // Ensure container width fits within parent
          height: '100%', // Ensure container takes full height of AvatarPlayer
          overflow: 'hidden', // Prevent any overflow
          border: '4px solid #707F97',
          borderRadius: '20px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)', // Shadow for styling
        }}
      >
        {loading && (
          <div className="loading-circle-container">
            <div className="spinner"></div>
          </div>
        )}

        {disconnected ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              color: '#707F97',
              fontSize: '18px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Disconnected due to inactivity. Please refresh to restart the session.
          </div>
        ) : (
          <>
            <video
              id="video"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover', // Ensure video fills container
                borderRadius: '15px', // Rounded corners for the video
                objectPosition: showPdfViewer ? '50% 0%' : `50% ${crop}`
                }}
              autoPlay
              playsInline
            ></video>
            <audio id="audio" autoPlay></audio>
          </>
        )}

        {/* Pause button */}
        {!loading && !disconnected && (
          <button
            onClick={handlePauseClick}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: '#707F97',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
            }}
            aria-label="Pause Button"
          >
            <Pause24Regular style={{ width: '30px', height: '30px' }} />
          </button>
        )}
      </div>
    </div>
  );
}
