import React, { useState, useEffect, useRef } from "react";
import QuestionInput from '../../components/QuestionInput/QuestionInput';
import aviationApi from './AviationApi';
import PdfViewer from '../../components/PdfViewer/PdfViewer';
import { useNavigate } from 'react-router-dom';

import styles from "./Chat.module.css";
import ctScannerStyles from "./CTScannerTheme.module.css";
import renalCancerStyles from "./RenalCancerTheme.module.css";
import generalPractitionerStyles from "./GeneralPractitionerTheme.module.css";

import { createClient } from '@anam-ai/js-sdk';
import { getSessionToken } from '../../components/Anam/utils/server-lib';
import { AnamEvent } from "@anam-ai/js-sdk/dist/module/types";
import AvatarPlayer from '../../components/Anam/AvatarPlayer';
import config from '../../config';

const getThemeDetails = (id, itemUrl) => {
    const item = config.find(configItem => configItem.id === id); // Find the item by its ID
    if (item) {
      let askUrl = ''; 
  
      // Construct the askUrl based on the ID
      switch (id) {
        case 0:
            askUrl = `${itemUrl}/stream-query`; 
            break;
        case 1:
            askUrl = `${itemUrl}/stream-query`; 
            break;
        case 2:
            askUrl = `${itemUrl}/stream-query-GP`; 
            break;
        case 3:
            askUrl = `${itemUrl}/stream-query-RCS`; 
            break;
        default:
          askUrl = `${itemUrl}/stream-query`; // Default case if no match
      }
  
      return { askUrl };
    }
  
    // Return empty askUrl if item not found
    return { askUrl: '' };
  };
  

const anamApiKey = process.env.REACT_APP_ANAM_API_KEY;

const Chat = ({ item }) => {
    const { askUrl } = getThemeDetails(item.id, item.url);
    const [messages, setMessages] = useState([]);
    const [pdfDetails, setPdfDetails] = useState(null);  
    const [initialPdfIndex, setInitialPdfIndex] = useState(null);  // State to store the initial index of the PDF clicked
    const chatEndRef = useRef(null);
    const [showPdfViewer, setShowPdfViewer] = useState(false); // New state to manage the PDF viewer visibility

    // Create a ref to store anamClient
    const anamClientRef = useRef(null);
    const [Anamloading, setAnamLoading] = useState(true); // State for loading
    const [isDisconnected, setIsDisconnected] = useState(false);
    // Set up a ref for the idle timeout
    const idleTimeoutRef = useRef(null);
    const talkStreamRef = useRef(null); // Initialize the ref

    // // First useEffect: Initialize Anam client
    useEffect(() => {
        const initializeAnamClient = async () => {
            try {
                const sessionToken = await getSessionToken(anamApiKey);

                // Initialize the Anam client with brains disabled
                const anamClient = createClient(sessionToken, {
                    personaId: item.personaid,
                    disableBrains: true, // Important: disable Anam's built-in LLM
                });

                anamClient.muteInputAudio();

                // Listen for video play event to hide loading circle and start idle timeout
                anamClient.addListener(
                    AnamEvent.VIDEO_PLAY_STARTED,
                    () => {
                        setAnamLoading(false);
                        resetIdleTimeout(); // Start idle timeout only after video starts playing
                    }
                );

                // Listen for message stream events and reset idle timer
                anamClient.addListener(
                    AnamEvent.MESSAGE_STREAM_EVENT_RECEIVED,
                    resetIdleTimeout
                );

                // Store the client in the ref so we can access it later
                anamClientRef.current = anamClient;

                // Start streaming to video and audio elements
                await anamClient.streamToVideoAndAudioElements('video', 'audio');

                // Cleanup function to disconnect when the component unmounts or session is not needed
                return () => {
                    disconnectAnamClient();
                };

            } catch (error) {
                if (error instanceof Error) {
                    console.log('Persona Busy: All our personas are currently busy. Please try again later.');
                } else {
                    console.error(error);
                }
            }
        };

        initializeAnamClient();

        return () => {
            disconnectAnamClient();  // Cleanup when the component unmounts
        };
    }, [anamApiKey, item.personaid]);

    // Function to disconnect the Anam client
    const disconnectAnamClient = () => {
        if (anamClientRef.current) {
            anamClientRef.current.stopStreaming(); // Ensure to stop the stream and disconnect
            anamClientRef.current = null;
            console.log("Anam end session");
            setIsDisconnected(true);
        }
    };

    // Function to reset the idle timeout
    const resetIdleTimeout = () => {
        clearTimeout(idleTimeoutRef.current);

        // Set a timeout to disconnect after 30 seconds of inactivity
        idleTimeoutRef.current = setTimeout(() => {
            disconnectAnamClient();
            console.log("Disconnected due to inactivity. Please restart the session.");
        }, 60000); // 30 seconds
    };

    useEffect(() => {
        const initializeListeners = () => {
            if (anamClientRef.current) {
                anamClientRef.current.addListener(AnamEvent.TALK_STREAM_INTERRUPTED, () => {
                    console.warn("Talk stream interrupted. Attempting to resume...");
                    try {
                        // Only reinitialize if no active stream exists
                        if (!talkStreamRef.current || !talkStreamRef.current.isActive()) {
                            talkStreamRef.current = anamClientRef.current.createTalkMessageStream();
                            console.log("Talk stream resumed.");
                        }
                    } catch (error) {
                        console.error("Failed to resume talk stream:", error);
                    }
                });                            }
        };
    
        initializeListeners();
    
        return () => {
            if (anamClientRef.current) {
                anamClientRef.current.removeAllListeners(); // Cleanup listeners on unmount
            }
        };
    }, []);    

    // Second useEffect: Handle scrolling when messages change or after Anam client initialization
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);  // Run this effect when the messages array is updated

    const handleSendMessage = async (question) => {
        setShowPdfViewer(false)

        setMessages((prevMessages) => [
            ...prevMessages,
            { text: question, sender: "user" }
        ]);

        let completeAssistantText = "";
        const systemMessageId = `system-message-${Date.now()}`;
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: ".", sender: "system", id: systemMessageId }
        ]);

        let dotAnimationInterval = setInterval(() => {
            setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                const systemMessageIndex = newMessages.findIndex((msg) => msg.id === systemMessageId);

                if (systemMessageIndex !== -1) {
                    const updatedMessage = newMessages[systemMessageIndex];
                    updatedMessage.text = '.'.repeat((updatedMessage.text.length % 3) + 1);
                }

                return newMessages;
            });
        }, 500);

        async function* textChunker(chunks) {
            const splitters = /([.?!])\s|[,;:()\[\]\{\}-]/g; // Matches sentence-ending punctuation and other split points
            let buffer = "";
        
            for (let text of chunks) {
                // Preprocess text to handle /n, /n1, **, and similar formatting
                text = preprocessTextForSpeech(text);
        
                let match;
                while ((match = splitters.exec(text)) !== null) {
                    const chunk = text.slice(0, match.index + 1); // Include the splitter
                    text = text.slice(match.index + 1); // Remaining text
                    yield buffer + chunk.trim() + " ";
                    buffer = "";
                }
                buffer += text;
        
                // If buffer grows too long, yield part of it
                if (buffer.length > 100) {
                    const lastSpace = buffer.lastIndexOf(" ");
                    if (lastSpace !== -1) {
                        yield buffer.slice(0, lastSpace + 1).trim() + " ";
                        buffer = buffer.slice(lastSpace + 1);
                    }
                }
            }
        
            if (buffer.trim()) {
                yield buffer.trim() + " ";
            }
        }
        
        // Helper function to preprocess text
        function preprocessTextForSpeech(text) {
            // Handle newlines and formatting
            text = text.replace(/\n\d*/g, " "); // Replaces /n, /n1, etc. with a space
            // Check if the chunk contains a "*" character and remove them if so
            if (text.includes("*")) {
                text = text.replace(/\*/g, ""); // Removes all * characters if any
            }
            // Handle bold markers (removes ** but keeps content inside)
            text = text.replace(/\*\*(.*?)\*\*/g, "$1");
            return text.trim();
        }
        
        try {
            const chatHistory = messages.map((msg) => ({
                role: msg.sender === 'user' ? 'user' : 'assistant',
                content: msg.text
            }));

            chatHistory.push({
                role: 'user',
                content: question
            });
            
            // Initialize a new talk stream if not already available
            if (!talkStreamRef.current || !talkStreamRef.current.isActive()) {
                try {
                    talkStreamRef.current = anamClientRef.current.createTalkMessageStream();
                    console.log("Talk stream initialized for new message.");
                } catch (error) {
                    console.error("Failed to create a talk stream:", error);
                    return; // Return early if initialization fails
                }
            }
        

            let lastChunkText = ""; // Define lastChunkText here

            await aviationApi.ask(chatHistory, askUrl, item.apikey, async (messageChunk) => {
                const chunkData = JSON.parse(messageChunk);

                if (chunkData?.type === "streamChunkMessage" && chunkData?.data?.text) {
                    const text = chunkData.data.text;
                    completeAssistantText += text;

                    // If talkStream is available, stream the message chunk
                    if (talkStreamRef.current && talkStreamRef.current.isActive()) {
                        try {
                            // Stream each chunk using the chunker
                            for await (const chunk of textChunker(text)) {
                                // console.log(chunk)
                                talkStreamRef.current.streamMessageChunk(chunk, false);
                            }
                        } catch (error) {
                            console.warn("Talk stream failed during streaming; skipping this chunk.");
                        }
                    }        

                    // Store the current chunk as the potential last chunk
                    lastChunkText = text;

                    setMessages((prevMessages) => {
                        const newMessages = [...prevMessages];
                        const systemMessageIndex = newMessages.findIndex((msg) => msg.id === systemMessageId);

                        if (systemMessageIndex !== -1) {
                            newMessages[systemMessageIndex].text = completeAssistantText;
                        }

                        return newMessages;
                    });

                    clearInterval(dotAnimationInterval);
                }

                if (chunkData?.type === "references_message" && chunkData?.data?.references) {
                    // Check if the current chunk is the last one before references message
                    if (talkStreamRef.current && talkStreamRef.current.isActive() && lastChunkText) {
                        try {
                            // Stream the last chunk only after all the chunks are done streaming
                            for await (const chunk of textChunker(lastChunkText)) {
                                talkStreamRef.current.streamMessageChunk("", true); // true indicates it's the final chunk
                            }
                        } catch (error) {
                            console.warn("Talk stream failed on the final chunk.");
                        }
                    }
            

                    const references = chunkData.data.references.slice(0, 3).map((ref) => ({
                        filename: ref.filename,
                        source: ref.source,
                        page: ref.page
                    }));

                    // Fetch the PDF URL for each reference
                    const pdfDetailsWithUrls = await Promise.all(
                        references.map(async (ref) => {
                            const pdfUrl = await aviationApi.generateUrl(item.url, item.apikey ,ref.source);
                            return { ...ref, pdfUrl };
                        })
                    );

                    // Update the messages with the references and PDF details
                    setMessages((prevMessages) => {
                        const updatedMessages = prevMessages.map((msg) => {
                            if (msg.id === systemMessageId) {
                                return { ...msg, text: completeAssistantText, references: pdfDetailsWithUrls };
                            }
                            return msg;
                        });
                        return updatedMessages;
                    });
                }
            });

        } catch (error) {
            console.error("Error calling the API:", error);
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: "Error calling the API.", sender: "system" }
            ]);
        }
    };

    const handleClearChat = () => {
        anamClientRef.current.talk("\n");
        console.log("TalkStream cleared.");

        // Clear history and reset the UI
        aviationApi.clearHistory();
        setMessages([]);
        setShowPdfViewer(false);
    };

    const renderMessage = (msg) => {
        const handleReferenceClick = (ref, index) => {
            setPdfDetails(msg.references); // Set the full pdfDetails array
            setInitialPdfIndex(index); // Set the index of the clicked reference
            setShowPdfViewer(true);
        };
    
        const parts = msg.text.split(/\/n/).filter(Boolean);
    
        return (
            <>
            {parts.map((part, index) => (
                <div key={index}>
                    {/* Step 2: Insert line breaks before numbered items (1., 2., etc.) */}
                    {part.split(/(\d\.\s)/).map((segment, i) => {
                        // Check if segment is a numbered item
                        const isNumberedItem = /^\d\.\s/.test(segment);
                        return (
                            <React.Fragment key={`${index}-${i}`}>
                                {isNumberedItem && i !== 0 && <br />} {/* Add line break before numbered items, except at start */}
                                {segment.split(/\*\*(.*?)\*\*/).map((innerSegment, j) =>
                                    j % 2 === 1 ? (
                                        <span key={`${index}-${i}-${j}`} style={{ fontWeight: "bold" }}>
                                            {innerSegment}
                                        </span>
                                    ) : (
                                        <span key={`${index}-${i}-${j}`}>{innerSegment}</span>
                                    )
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            ))}
                {msg.references && (
                    <div style={{ marginTop: "10px", display: "flex", flexWrap: "wrap", gap: "10px"}}>
                        {msg.references.map((ref, index) => (
                            <div
                                key={index}
                                onClick={() => handleReferenceClick(ref, index)}
                                style={{
                                    padding: "5px 10px",
                                    borderRadius: "20px",
                                    backgroundColor: "#fff",
                                    color: "#707F97",
                                    cursor: "pointer",
                                    border: "1px solid #3C4655",
                                    textAlign: "center",
                                    minWidth: "100px",
                                    transition: "background-color 0.3s ease", // Smooth transition
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = "#3C4655"; // Highlight background on hover
                                    e.target.style.color = "#fff"; // Change text color to white on hover
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = "#fff"; // Reset background color
                                    e.target.style.color = "#707F97"; // Reset text color
                                }}
                            >
                                {ref.filename} - Page {ref.page}
                            </div>
                        ))}
                    </div>
                )}
            </>
        );
    };
    
    const navigate = useNavigate();

    const handleTitleClick = () => {
        navigate('/'); // Redirects to the main page
      };

      return (
    <div className="section" style={{ height: '100vh', margin: '0', padding: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', width: '100%', gap: '20px'}}>
            <div className={`${styles.titleBox} box`} style={{ flex: 1, cursor: "pointer" }} onClick={handleTitleClick}>
                    <h1 className="has-text-white">{item.title}</h1>
                </div>
                <button
                onClick={handleClearChat}
                className={styles.chatbutton}
                >
                    New Chat
                </button>
            </div>
    
            <div className={styles.chatContainer} style={{ position: 'relative' }}>
                <div className={styles.chatBox}>
                    {/* PdfViewer positioned above both video and text areas */}
                    {showPdfViewer && pdfDetails && initialPdfIndex !== null && (
                        <div className={styles.pdfViewerWrapper}>
                            <PdfViewer
                                pdfDetails={pdfDetails}
                                initialPdfIndex={initialPdfIndex}
                                setShowPdfViewer={setShowPdfViewer}
                            />
                        </div>
                    )}
    
                    <div className={styles.chatContent}>
                        {/* Video Area */}
                        <div className={styles.videoArea}>
                            <AvatarPlayer loading={Anamloading} showPdfViewer={showPdfViewer} anamClient={anamClientRef.current} disconnected={isDisconnected} crop={item.ipadcrop}/>
                        </div>
    
                        {/* Text Area */}
                        <div className={styles.textArea}>
                            {messages.length === 0 && (
                                <div className={`box`} style={{ marginBottom: '0rem', marginTop: 'auto', backgroundColor: 'white' }}>
                                    <ul style={{ padding: 0, listStyleType: 'none' }}>
                                        {item.questions.map((question, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleSendMessage(question)}
                                                className={`${styles.questionItem} ${index % 2 === 0 ? styles.even : styles.odd}`}
                                                >
                                                {question}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
    
                            {messages.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`${styles.messageBubble} ${msg.sender === "user" ? styles.userMessage : styles.systemMessage}`}
                                >
                                    {renderMessage(msg)}
                                </div>
                            ))}
                            <div ref={chatEndRef} />
                        </div>
                    </div>
    
                    <QuestionInput
                        onSend={handleSendMessage}
                        disabled={false}
                        placeholder={messages.length === 0 ? "Pick a sample question above, or tap to ask your own." : "Speak to the persona:"}
                        clearOnSend={true}
                        onClearChat={handleClearChat}
                    />
                </div>
            </div>
        </div>
    );
        
};

export default Chat;
