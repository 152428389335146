import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar.js';
import Home from './pages/Home/Home.js';
import MetaTags from './components/MetaTags.js'; 
import Chat from './pages/Chat/Chat.js';
import config from './config'; // Import config
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <MetaTags />
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Add dynamic path for each item */}
          {config.map((item) => (
            <Route 
              key={item.id} 
              path={`/${item.title.replace(/\s+/g, '_')}`} 
              element={<Chat item={item} />} // Pass the item directly as a prop to Chat
            />
          ))}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
