export const getSessionToken = async (apiKey) => {
    try {
      const response = await fetch(`https://api.anam.ai/v1/auth/session-token`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
        cache: 'no-cache', // Prevents caching to ensure dynamic rendering
      });
  
      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`Error fetching session token: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('Anam Session token fetched:', data.sessionToken);
  
      return data.sessionToken;
    } catch (error) {
      console.error('Failed to fetch session token:', error);
      throw error;
    }
  };
  