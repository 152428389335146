import React from 'react';
import { Link } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import config from '../../config';
import './Home.css';
import leoImage from '../../images/leo.png'; // Import the image from local folder
import styles from "../Chat/Chat.module.css";

const Home = () => {
  const openGuide = () => {
    // Redirect to the Notion page URL
    window.location.href = 'https://laced-makeup-713.notion.site/RSNA-Event-Mesmerise-AI-Healthcare-Demos-14641cc54da7804bbf95cd7913a93c2d';
  };

  return (
<div className="background-container">
  <div className="content-wrapper">
    {/* Main Section */}
    <div className="section" style={{ height: 'auto', margin: '0', padding: '20px', paddingRight: '0px' }}>
      {/* Title Section */}
      <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', width: '100%', gap: '20px' }}>
        <div className="box titleBox" style={{ flex: 1 }}>
          <h1 className="title has-text-white">Live Video Persona Demos</h1>
        </div>
        <button onClick={openGuide} className={styles.chatbutton}>
          Guide
        </button>
      </div>

      {/* Content Below Title Section */}
      <div className="center-content">
        <ul className="bullets">
          <li>Pick which persona you would like to interact with</li>
          <li>Use our Guides to ensure you understand and convey the key messages and takeaways</li>
        </ul>

        {/* Persona Boxes in Two Per Row */}
        <div className="columns is-multiline is-centered mb-6">
          {config.map((item) => (
            <div key={item.id} className="column is-half">
              <Link to={`/${item.title.replace(/\s+/g, '_')}`} className="no-decoration">
                <div data-id={item.id} className="box-container">
                  <div className="content">
                    <div className="imageContainer">
                      <img src={item.image || leoImage} alt={item.title} className="personaImage" />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                      <h1 className="box-title has-text-left" style={{ color: 'white' }}>
                        {item.title}
                      </h1>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>

  {/* Footer */}
  <footer className="footer">
    <div className="content has-text-centered">
      <p style={{ margin: '0' }}>© Copyright Mesmerise Solutions UK Ltd 2024</p>
    </div>
  </footer>
</div>

  );
};

export default Home;
