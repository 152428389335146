import axios from 'axios';

class AviationApi {
    constructor() {
        this.chatHistory = [];
    }

    // Function to ask a question to the API with dynamic URL and API Key from item
    async ask(chatHistory, itemUrl, itemKey, onMessageChunk) {
        try {
          const wsUrl = `${itemUrl}?api-key=${itemKey}`
          const websocket = new WebSocket(wsUrl);
          const payload = JSON.stringify({ input: chatHistory[chatHistory.length - 1].content, chat_history: chatHistory });
      
          return new Promise((resolve, reject) => {
            websocket.onopen = () => {
              console.log("WebSocket connection established.");
              websocket.send(payload); // Send the payload once the WebSocket is open
            };
      
            let completeMessage = "";
      
            websocket.onmessage = (event) => {
              const messageChunk = event.data;
      
              // Check if onMessageChunk is a function before calling it
              if (typeof onMessageChunk === "function") {
                onMessageChunk(messageChunk); // Call the callback with the chunk
              } else {
                console.warn("onMessageChunk is not a function.");
              }
      
              completeMessage += messageChunk; // Accumulate the complete message
            };
      
            websocket.onerror = (error) => {
              console.error("WebSocket Error:", error);
              reject(new Error(`WebSocket error: ${error.message}`)); // Reject with an error
            };
      
            websocket.onclose = () => {
              console.log("WebSocket connection closed.");
              resolve(completeMessage); // Resolve with the complete message
            };
          });
        } catch (error) {
          console.error("Error in WebSocket connection:", error);
          throw new Error(`Failed to connect to WebSocket: ${error.message}`);
        }
      }
          

    // Function to clear chat history
    clearHistory() {
        this.chatHistory = [];
    }

    // // Function to generate a PDF URL
    // async generatePdfUrl(chapter, start, end) {
    //     // Use chapter directly as the filename (already formatted correctly)
    //     const filename = chapter;

    //     const url = `${apiUrl}/create_pdf_url?filename=${filename}&start_page=${start}&end_page=${end}`;

    //     try {
    //         const response = await axios.get(url, {
    //             headers: {
    //                 'accept': 'application/json',
    //                 'x-api-key': apiKey
    //             }
    //         });
            
    //         if (response.status === 200) {
    //             return response.data.pdf_sas_url;
    //         } else {
    //             console.error(`Error: API request failed with status code ${response.status}`);
    //             return null;
    //         }
    //     } catch (error) {
    //         console.error("Error generating PDF URL: ", error);
    //         return null;
    //     }
    // }


    // Function to generate a URL of an existing PDF in blob storage
    async generateUrl(itemUrl, itemKey, blobName) {
        const url = `${itemUrl}/get_pdf_url?filename=${blobName}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': itemKey
                }
            });
            if (response.status === 200) {
                return response.data.pdf_sas_url;
            } else {
                console.error(`Error: API request failed with status code ${response.status}`);
                return null;
            }
        } catch (error) {
            console.error("Error generating URL: ", error);
            return null;
        }
    }
}

export default new AviationApi();
