import evelynImage from './images/evelyn.png'; // Importing the image directly
import pabloImage from './images/pablo.png';
import leoImage from './images/leo.png';
import caraImage from './images/cara.png';

const config = [
  {
    id: 0,
    title: "Photon-Counting CT",
    image: leoImage,
    url: "https://msg-prd-uks-ctrag-webapp-api.azurewebsites.net",
    apikey: "XtvhWJ2XY0WQJyRBQZWPJj-GUF6QtQKVA9AAYosskd9vdWhsJn17C_bfYBevhmlANAcp1d4lJJTgfPov4kM9fw",
    personaid: "9c955db3-b78a-48b6-be24-98d485b4a6bd",
    ipadcrop: '30%',
    questions: [
      "What are the benefits of photon-counting scanners vs. traditional CT scanners?",
      "What specific clinical entities is photon counting CT used for?",
      "How does photon-counting reduce radiation dose?",
      'What are the specific benefits of photon-counting  in musculoskeletal applications?',
      'What are the specific benefits of photon-counting in cardiovascular applications?',
      'How to keep an anxious patient calm during a CT scan?'
    ]
  },
  {
    id: 1,
    title: "MRI Safety AI Trainer",
    image: caraImage,
    url: "https://msg-prd-uks-mri-safe-webapp-api.azurewebsites.net",
    apikey: "f7d3ccd2-037a-4428",
    personaid: "519c41ab-88d3-46c8-896d-1ad7a62069e9",
    ipadcrop: '10%',
    questions: [
      "What are the 4 zones of a MRI facility?",
      "What is the differences between Level 1 and Level 2 employees that need to work in MRI?",
      "What role does the patients screening play in MRI safety?",
      "What to do if the patient has a code in the MRI scanner, level IV?",
      "Why monitoring and limiting SAR is important for MRI safety?",
      "What emergency safety actions should be taken if a patient is pinned against the MRI scanner by a magnetic object?",
      "What procedures are necessary to prevent burns in the MRI scanner?",
    ]
  },
  {
    id: 2,
    title: "Subspecialist: Renal Cell Cancer",
    image: evelynImage,
    url: "https://msg-prd-uks-renal-ca-webapp-api.azurewebsites.net",
    apikey: "renal-cancer",
    personaid: "761a7fbc-c4a4-43d3-9bb9-91bbaff276ef",
    ipadcrop: '30%',
    questions: [
      "What are the primary treatment options for renal cancer?",
      "What criteria should be considered when selecting a treatment for renal cancer?",
      "How should a patient's age influence treatment selection and management?"
    ]
  },
  {
    id: 3,
    title: "Patient Facing: New Kidney Cancer Diagnosis",
    image: pabloImage,
    url: "https://msg-prd-uks-renal-ca-webapp-api.azurewebsites.net",
    apikey: "renal-cancer",
    personaid: "4965dfa7-a0fe-4335-9235-fde45faffa62",
    ipadcrop: '25%',
    questions: [
      "I am nervous about this new diagnosis. What treatments are there for me?",
      "Will the entire kidney need to be removed, or only the tumor?",
      "Is there anything I could have done to prevent kidney cancer?",
      "Will my diet or lifestyle change significantly if my kidney needs to be removed?",
      "What is a biopsy? Are there risks?",
    ]
  }
];

export default config;
