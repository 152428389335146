import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import logoDesktop from '../images/Mesmerise-logo-left-aligned-colour-for-white-background-RGB.png.png';
import logoMobile from '../images/Mesmerise-logo-centred-for-light-background-RGB.png';
import config from '../config';

const Navbar = () => {
  const [selectedVertical, setSelectedVertical] = useState(null);
  const location = useLocation(); // Get current location

  useEffect(() => {
    // When location changes, determine and set the selected vertical
    const path = decodeURIComponent(location.pathname);
    const title = path.substring(path.lastIndexOf('/') + 1);
    const selected = config.find(item => item.title === title);
    setSelectedVertical(selected);
  }, [location]);

  return (
    <>
      <nav className="navbar is-white" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={logoDesktop} alt="Mesmerise Logo" className="desktop-logo is-hidden-mobile" />
            <img src={logoMobile} alt="Mesmerise Logo" className="mobile-logo is-hidden-tablet" />
          </Link>
        </div>
        {/* <div className="navbar-end" style={{ display: 'flex' }}>
          <Link to="/" className="navbar-item" style={{ color: '#75716F' }}>
            <FontAwesomeIcon icon={faHome} />
            <span className="ml-2 is-hidden-mobile">Home</span>
          </Link>
        </div> */}
      </nav>
    </>
  );
};

export default Navbar;
